<script>

export default {
  name: "AppHeader",
  data() {
    return {
    };
  }
};
</script>

<template>
  <header>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg py-lg-0 fixed-header">
      <div class="container">
        <router-link :to="{ name: 'benvenuti-page' }" class="navbar-brand fw-bolder ms_text_main_darker">
          <div class="navbar-logo d-flex align-items-center">
            S+E
            <img src="../assets/img/vesuvio-mole.png" alt="vesuvio e mole">
          </div>
        </router-link>

        <button class="navbar-toggler shadow-none" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <div class="line-1"></div>
          <div class="line-2"></div>
          <div class="line-3"></div>
        </button>

        <div class="offcanvas offcanvas-end z-foreground offcanvas-navbar" tabindex="-1" id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel" ref="offcanvas">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title font-secondary ms_text_main_darker" id="offcanvasNavbarLabel">
              Menu
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body d-flex flex-lg-row">

            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 mx-auto text-center text-lg-start">
              <li class="nav-item mx-lg-3 my-1 my-lg-0">
                <router-link :to="{ name: 'benvenuti-page' }" class="ms-nav-link py-4" exact active-class="active-link"
                  aria-current="page">
                  BENVENUTI
                </router-link>
              </li>
              <li class="nav-item mx-lg-3 my-1 my-lg-0">
                <router-link :to="{ name: 'conferma-presenza' }" class="ms-nav-link py-4" exact
                  active-class="active-link" aria-current="page">
                  CONFERMA PRESENZA
                </router-link>
              </li>
              <li class="nav-item mx-lg-3 my-1 my-lg-0">
                <router-link :to="{ name: 'foto' }" class="ms-nav-link py-4" exact active-class="active-link"
                  aria-current="page">
                  FOTO
                </router-link>
              </li>
              <li class="nav-item mx-lg-3 my-1 my-lg-0">
                <router-link :to="{ name: 'contattaci' }" class="ms-nav-link py-4" exact active-class="active-link"
                  aria-current="page">
                  CONTATTACI
                </router-link>
              </li>
              <li class="nav-item mx-lg-3 my-1 my-lg-0">
                <router-link :to="{ name: 'lista-nozze' }" class="ms-nav-link py-4" exact active-class="active-link"
                  aria-current="page">
                  LISTA NOZZE
                </router-link>
              </li>
              <li class="nav-item mx-lg-3 my-1 my-lg-0">
                <router-link :to="{ name: 'dove-dormire' }" class="ms-nav-link py-4" exact active-class="active-link"
                  aria-current="page">
                  DOVE DORMIRE
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
  </header>
</template>

<style scoped lang="scss"></style>
