import { createRouter, createWebHistory } from "vue-router";
import BenvenutiPage from "./pages/BenvenutiPage.vue";
import ConfermaPresenza from "./pages/ConfermaPresenza.vue";
import ContattaciForm from "./pages/ContattaciForm.vue";
import FotoGallery from "./pages/FotoGallery.vue";
import ListaNozze from "./pages/ListaNozze.vue";
import DoveDormire from "./pages/DoveDormire.vue";
import AdminLogin from './pages/Admin/AdminLogin.vue';
import AdminDashboard from './pages/Admin/AdminDashboard.vue';
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "benvenuti-page",
      component: BenvenutiPage,
    },
    {
      path: "/conferma-presenza",
      name: "conferma-presenza",
      component: ConfermaPresenza,
      props: true,
    },
    {
      path: "/foto",
      name: "foto",
      component: FotoGallery,
      props: true,
    },
    {
      path: "/contattaci",
      name: "contattaci",
      component: ContattaciForm,
      props: true,
    },
    {
      path: "/lista-nozze",
      name: "lista-nozze",
      component: ListaNozze,
      props: true,
    },
    {
      path: "/dove-dormire",
      name: "dove-dormire",
      component: DoveDormire,
      props: true,
    },
    {
      path: "/login",
      name: "login",
      component: AdminLogin,
      props: true,
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: AdminDashboard,
      props: true,
    }
  ],
});

// Verifica del token per le rotte protette
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !token) {
    next('/login');
  } else if (requiresAuth && token) {
    // Verifica della validità del token (controllo base)
    const payload = JSON.parse(atob(token.split('.')[1]));
    const isExpired = payload.exp * 1000 < Date.now();
    if (isExpired) {
      localStorage.removeItem('token');
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;