<script>
</script>

<template>
    <div class="foto-gallery">
        <h1>Album digitale</h1>
        <p>Ci conoscete, non siamo i tipi da stare ore e ore in posa, quindi abbiamo chiesto al nostro amico e fotografo di essere discreto.</p>
        <p>Preferiamo rivivere il più possibile la giornata attraverso i vostri scatti.</p>
               
        <div class="jumbotron-foto-gallery">
            <a href="https://photos.app.goo.gl/rCs9GckmFtaQzNoaA" target="_blank" class="cornice-link">
                <h1>Caricateli pure in questa cartella <i class="fas fa-folder-open"></i></h1>
                <div class="cornice">
                <div class="foto-interna">
                    <img src="../assets/img/se-mare-polaroid.png" alt="Descrizione foto">
                    <div class="foto-descrizione">
                        <p class="descrizione-polaroid shadows-into-light-regular">Serena ed Emmanuel</p>
                        <p class="data-polaroid shadows-into-light-regular">02-05-2025</p>
                    </div>
                    <div class="overlay-icon">
                        <i class="fas fa-folder-open"></i>
                    </div>
                </div>
                </div>
            </a>
        </div>

        <h1>Grazie!!!!🫶🏻</h1>
    </div>
    
</template>