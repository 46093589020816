<script>

//import AppMainSection from "../components/AppMainSection.vue";

export default {
    name: "benvenuti-page",
    components: {
        //AppMainSection,
    },
    data() {
        return {
        };
    }
};
</script>

<template>
    <div class="jumbotron-content">
        <div class="jumbotron">
            <img src="../assets/img/setest.jpeg" alt="Serena ed Emmanuel">
        </div>
        <h1 class="centered-text">SERENA & EMMANUEL</h1>
    </div>
    <div class="info">
        <div class="data-luogo-container">
            <div class="row align-items-center">
                <div class="col d-flex justify-content-start rose-container">
                    <img src="../assets/img/whiterose.png" alt="rosa bianca" class="img-fluid">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="info-content">
                        <h1>Siamo felici di invitarvi al nostro matrimonio!</h1>
                        <p class="info-ringraziamenti">Un GRAZIE speciale a tutte le persone che ci sono sempre state vicino, senza le quali non avremmo fatto questo importante passo. Per questo, vorremmo condividerlo con voi!!!</p>
                        <hr class="info-hr">
                    </div>
                </div>
            </div> 
            <div class="row">
                <div class="col"> 
                    <div class="data-luogo-content">
                        <h1 class="data">VENERDÌ 2 MAGGIO 2025</h1>
                        <h2 class="orario">Ore 15:45</h2>
                        <h2 >Basilica Cattedrale “Santa Maria Assunta”</h2>
                        <h2 class="chiesa">Piazza Orsini 27, BENEVENTO</h2>
                        <hr class="info-hr">
                        <p class="postcerimonia">Dopo la cerimonia vi aspettiamo per festeggiare insieme a</p>
                        <h2>CASA LERARIO</h2>
                        <h3>Contrada Laura 6, 82030 Melizzano BN</h3>
                        <hr class="info-hr mt-4 mb-4">
                        <h3>E' gradita la conferma entro 1 APRILE 2025</h3>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col d-flex justify-content-end rose-container">
                    <img src="../assets/img/whiterose.png" alt="rosa bianca" class="img-fluid rotate-180">
                </div>
            </div>
            <!--Prima erano qui le info-->
        </div>
    </div>
    <div class="jumbotron-content">
        <div class="jumbotron">
            <img src="../assets/img/napoli.jpeg" alt="Serena ed Emmanuel">
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use "../styles/partials/colors" as *;

</style>