<template>
    <div class="login-container">
      <h1 >Login</h1>
      <form class="form-login" @submit.prevent="login">
        <div>
          <label>Username</label>
          <input v-model="username" type="text" required />
        </div>
        <div>
          <label>Password</label>
          <input v-model="password" type="password" required />
        </div>
        <button type="submit" class="primary-button">Login</button>
      </form>
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </div>
  </template> 
   
  <script> 
  import axios from 'axios';
  import config from '@/config';
  export default {
    data() {
      return {
        apiBaseUrl: config.apiBaseUrl,
        username: '',
        password: '',
        errorMessage: ''
      };
    },
    methods: {
      async login() {
        try {
          this.$store.commit('setIsLoading', true);
          const response = await axios.post(`${this.apiBaseUrl}/api/login`, {
            username: this.username,
            password: this.password
          });
          if (response.data.success) {
            const token = response.data.message; // Il token JWT
            localStorage.setItem('token', token); // Salvataggio del token nel localStorage
            this.$router.push('/dashboard'); // Reindirizzamento alla dashboard
          }
        } catch (error) {
          this.errorMessage = 'Login fallito. Verifica le tue credenziali.';
        } finally{
          this.$store.commit('setIsLoading', false);
        }
      }
    }
  };
  </script>
  