<script>
import ErrorAlert from "../components/ErrorAlert.vue";
import config from '@/config';
import axios from 'axios';

export default {
  name: "ConfermaPresenza",
  components: {
    ErrorAlert
  },
  data() {
    return {
        apiBaseUrl: config.apiBaseUrl,
        step: 1,
        id:0,
        nome: '',
        cognome: '',
        guests: [],
        filteredGuests: [],
        ListaPartecipanti: [],
        selectedGuest: {
            Id:0,
            NomeIntero:"",
            Presenza:null,
            IdDiete: [],
            Altro:null,
            Passaggio: null,
            WhiteList: null
        },
        errorMessage: '',
        showThankYou: false,
        EndOrAdd: false,
        Diete: [],
    };
    },
    methods: {
        async searchGuest() {
            try {
                this.$store.commit('setIsLoading', true);
                const payload = {
                    Nome: this.nome,
                    Cognome: this.cognome
                };
                
                const response = await axios.post(`${this.apiBaseUrl}/api/SearchInvitatoPresenza`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        //'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                // Axios restituisce automaticamente il JSON, non serve response.json()
                const result = response.data; 

                if (result.success && result.data.length > 0) {
                    this.guests = result.data;
                    this.step = 2;
                } else if (result.success && result.data.length === 0) {
                    this.errorMessage = 'Nome non trovato. Contatta gli organizzatori.';
                } else {
                    console.error("Errore nella risposta:", result.message);
                }

            } catch (error) {
                console.error("Errore durante la ricerca dell'invitato:", error);
            } finally{
                this.$store.commit('setIsLoading', false);
            }
        },
        async GetDatiInvitato(){
            try {
                this.$store.commit('setIsLoading', true);
                const response = await axios.get(`${this.apiBaseUrl}/api/getInvitatoById?id=${this.selectedGuest.id}`, {
                headers: {
                    //'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
                });

                // Accesso ai dati direttamente da response.data
                const result = response.data;

                if (result.success) {
                this.selectedGuest = {
                    id: result.data.id,
                    NomeIntero: result.data.nomeIntero || '',
                    Presenza: result.data.presenza,
                    IdDiete: result.data.idDiete || [],
                    Altro: result.data.altro,
                    Passaggio: result.data.passaggio,
                    WhiteList: result.data.whiteList
                };
                console.log("GetDatiInvitato OK:",this.selectedGuest);
                } else {
                console.error("Errore nella risposta:", result.message);
                }
            } catch (error) {
                console.error("Errore durante la modifica dell'invitato:", error);
            } finally{
                this.$store.commit('setIsLoading', false);
            }
        },
        ResetSelectedInvitato(){
            this.selectedGuest=  {
                Id:0,
                NomeIntero:"",
                Presenza:null,
                IdDiete: [],
                Altro:null,
                Passaggio: null,
                WhiteList: null
            }
        }
        ,nextStep() {
            if(this.step===2){
                this.GetDatiInvitato();
                this.step++;
            }
            else if (this.step === 3 && this.selectedGuest.Presenza === false) {
                this.AggiungiInvitatoList();
                this.step = 8;
            } else if(this.step === 4){
                if(this.selectedGuest.WhiteList){
                    this.step=6;
                } else {
                    this.editInvitato();
                    this.step=10;
                }
            } else if(this.step===6) {
                this.editInvitato();
                this.step = 10;
            }else if(this.step===10){
                if(this.EndOrAdd){//1:Aggiungi nuovo
                    console.log("endOrAdd true");
                    this.resetForm();
                    this.step = 1; 
                } else {
                    console.log("endOrAdd false");
                    this.step = 10;
                }
            } else if (this.step === 8 && this.showThankYou) { //non ci sarà
                this.editInvitato();
                this.step=10;
            } else if (this.step === 8 && !this.showThankYou) {//ha sbagliato ci sarà
                this.step = 3;
            } else {
                this.step++;
            }

        },
        previousStep(){
            if(this.step===6){
                this.step--;
            }
            this.step--;
        },
        handleSubmit() {
            //type="submit" su button
        },
        confirmNo() {
            this.showThankYou = true;
            this.nextStep();
        },
        cancelNo() {
            this.showThankYou = false;
            this.step = 3;
        }, 
        async editInvitato() {
          try {
            console.log("EditInvitato Start:",this.selectedGuest);
            this.$store.commit('setIsLoading', true);
            const response = await axios.put(`${this.apiBaseUrl}/api/EditInvitato`,this.selectedGuest, {
                headers: {
                    'Content-Type': 'application/json',
                    //'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const result = response.data;
            if (result.success) {
                console.log("EditInvitato OK:",this.selectedGuest);
                console.log("ListaParteci pantipanti PRIMA di +:",this.ListaPartecipanti);
                this.AggiungiInvitatoList();
                this.Nome="";
                this.Cognome="";
                console.log("ListaParteci pantipanti DOPO +:",this.ListaPartecipanti);
            } else {
              console.error("Errore nella risposta:", result.message);
            }
          } catch (error) {
            console.error("Errore durante la modifica dell'invitato:", error);
          } finally{
            this.$store.commit('setIsLoading', false);
          }
        },
        AggiungiInvitatoList(){
            console.log("AggiungiInvitato Start:",this.selectedGuest);
            const existingParticipant = this.ListaPartecipanti.find(participant => 
                participant.Id === this.selectedGuest.id
            );
            if(existingParticipant && existingParticipant.Id!=undefined){
                existingParticipant.Id = this.selectedGuest.id,
                existingParticipant.NomeIntero= this.selectedGuest.NomeIntero,
                existingParticipant.Presenza= this.selectedGuest.Presenza,
                existingParticipant.IdDiete= this.selectedGuest.IdDiete,
                existingParticipant.Altro= this.selectedGuest.Altro,
                existingParticipant.Passaggio= this.selectedGuest.Passaggio,
                existingParticipant.WhiteList= this.selectedGuest.WhiteList
            } else {
                console.log("non eisteeeeeeeeeee")
                this.ListaPartecipanti.push({
                    Id: this.selectedGuest.id,
                    NomeIntero: this.selectedGuest.NomeIntero,
                    Presenza: this.selectedGuest.Presenza,
                    IdDiete: this.selectedGuest.IdDiete,
                    Altro: this.selectedGuest.Altro,
                    Passaggio: this.selectedGuest.Passaggio,
                    WhiteList: this.selectedGuest.WhiteList
                });
            }
            this.ResetSelectedInvitato();
        },
        AggiungiInvitato(){
            this.EndOrAdd = true;
            this.nextStep();
        },
        EditRiepilogo(id) {
            this.searchGuest.Id = id;
            this.selectedGuest.id = id;
            this.GetDatiInvitato();
            this.step = 3;
        },
        resetForm() {
            this.filteredGuests = [];
            this.selectedGuest = null;
        },
        formatOptions(dieteIds, altro) {
            return dieteIds
                .map(id => {
                    const dieta = this.Diete.find(d => d.id === id);
                    if (dieta) {
                        return dieta.id === 5 ? altro : dieta.dieta;
                    }
                    return '';
                }).join(', ');
        },
        async getDiete() {
          try {
            this.$store.commit('setIsLoading', false);
            const response = await axios.get(`${this.apiBaseUrl}/api/GetDiete`);
            const result = response.data; 
            if (result.success) {
              this.Diete = result.data.result
            } else {
              console.error("Errore nella risposta:", response.message);
            }
          } catch (error) {
            console.error("Errore durante la modifica dell'invitato:", error);
          }finally{
            this.$store.commit('setIsLoading', false);
          }
        }
    }, mounted(){
        this.getDiete();
    }
};

</script>

<template>
    <div class="conferma-presenza-container">
        <div class="rosebianche">
            <img src="../assets/img/whiterose.png" alt="rose bianche">
            <img src="../assets/img/whiterose.png" alt="rose bianche">
        </div>
        <div class="info-presenza">
            <h1 class="title-presenza">Condividerai con noi questo giorno importante?</h1>
            <p>Per confermare la vostra presenza alle nozze vi basterà cercare il vostro nome.</p>
            <p>P.S. Se dovessero esserci omonimi, segnarsi con doppio cognome (paterno e materno).</p>
            <p>Se non doveste trovare il vostro nome contattateci senza problemi.</p>
            <hr class="info-presenza-hr">
        </div>
        <form @submit.prevent="handleSubmit">
            <div v-if="step === 1" class="nominativi">
                <input type="text" id="firstName" v-model="nome" placeholder="Nome" required>
                
                <input type="text" id="lastName" v-model="cognome" placeholder="Cognome" required>
                <div v-if="errorMessage">
                    <ErrorAlert :message="errorMessage" />
                </div>
                <button type="button" class="primary-button" @click="searchGuest">Cerca</button>
            </div>

            <div v-if="step === 2">
                <div class="scelta-invitato">
                    <h1>Seleziona il tuo nome</h1>
                    <div class="card-invitato" v-for="(guest, index) in guests" :key="index">
                        <input type="radio" :id="'guest-' + index" :value="guest" v-model="selectedGuest">
                        <label :for="'guest-' + index">{{ guest.nomeIntero }}</label>
                    </div>
                    <div class="navigazioneForm">
                        <button type="button" class="primary-button" @click="previousStep">Indietro</button>
                        <button type="button" class="primary-button" @click="nextStep" v-if="selectedGuest" >Avanti</button>
                    </div>
                </div>
            </div>

            <div v-if="step === 3">
                <div class="conferma">
                    <h1>Condividerai con noi questo giorno importante?</h1>
                    <div class="risposte-container">
                        <div class="card-risposta">
                            <input type="radio" id="yes" :value="true" v-model="selectedGuest.Presenza">
                            <label for="yes">Sì</label>
                        </div>
                        <div class="card-risposta">
                            <input type="radio" id="no" :value="false" v-model="selectedGuest.Presenza">
                            <label for="no">No</label>
                        </div>
                    </div>
                    <div class="navigazioneForm">
                        <button type="button" class="primary-button" @click="previousStep">Indietro</button>
                        <button type="button" class="primary-button" @click="nextStep" v-if="selectedGuest.Presenza!=null">Avanti</button>
                    </div>
                    
                </div>
            </div>

            <div v-if="step === 4">
                <div class="intolleranze">
                    <h1>Hai qualche intolleranza o segui qualche dieta? (scegliere 1 o + opzioni)</h1>
                    <div class="risposte-container">
                        <div class="card-risposta">
                            <div v-for="dieta in this.Diete" :key="dieta.id">
                                <input type="checkbox" :id="dieta.dieta" :value="dieta.id" v-model="selectedGuest.IdDiete">
                                <label :for="dieta.dieta">{{ dieta.dieta }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedGuest.IdDiete.includes(5)">
                        <textarea class="generic-textarea" v-model="selectedGuest.Altro" placeholder="Specificare dieta"></textarea>
                    </div>
                    <div class="navigazioneForm">
                        <button type="button" class="primary-button" @click="previousStep">Indietro</button>
                        <button type="button" class="primary-button" v-if="selectedGuest.IdDiete.length > 0 && (selectedGuest.IdDiete.includes(5) ? (selectedGuest.Altro != null && selectedGuest.Altro.length > 0) : true)" @click="nextStep">Avanti</button>
                    </div>
                </div>
            </div>


            <div v-if="step === 6 && selectedGuest.Presenza === true">
                <div class="passaggio">
                    <h1 class="text-center">Avrai bisogno di un passaggio andata e ritorno Benevento-Melizzano (luogo del ricevimento)?</h1>
                    <img  class="img-maps" src="../assets/img/maps.png" alt="percorso chiesa ricevimento">
                    <div class="risposte-container">
                        <div class="card-risposta">
                            <input type="radio" id="transportYes" :value="true" v-model="selectedGuest.Passaggio">
                            <label for="transportYes">Si</label>
                        </div>
                        <div class="card-risposta">
                            <input type="radio" id="transportNo" :value="false" v-model="selectedGuest.Passaggio">
                            <label for="transportNo">No</label>
                        </div>
                    </div>
                    <div class="navigazioneForm">
                        <button type="button" class="primary-button" @click="previousStep">Indietro</button>
                        <button class="primary-button" @click="nextStep" v-if="selectedGuest.Passaggio||!selectedGuest.Passaggio">Invia Conferma</button>
                    </div>
                </div>
            </div>

            <div v-if="step === 8">
                <div class="conferma-negativa">
                    <h1>Sei sicuro di voler rinunciare alla presenza?</h1>
                    <div class="rinuncia">
                        <button type="button" class="primary-button" @click="confirmNo">Si, purtroppo non ci sarò</button>
                        <button type="button" class="primary-button" @click="cancelNo">Annulla</button>
                    </div>
                </div>
                </div>

                <div v-if="step === 9">
                <div class="ringraziamento-rinuncia">
                    <h1>Grazie per averci informato. Speriamo di vederti in un'altra occasione!</h1>
                </div>
            </div>
            <div v-if="step === 10">
                <div class="riepilogo">
                    <h1>Riepilogo:</h1>
                    <div class="card-riepilogo-container">
                        <div v-for="(guest, index) in this.ListaPartecipanti" :key="index" class="card-riepilogo">
                            <!-- Card per "Non Confermato" -->
                            <div class="NonConfermato" v-if="guest.Presenza === false">
                                <div class="h1-content">
                                    <h1>{{guest.NomeIntero}}</h1>
                                    <button @click="EditRiepilogo(guest.Id)" class="edit-button">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </button>
                                </div>
                                <p>Non ci sarò</p>
                            </div>
                            
                            <!-- Card per "Confermato" -->
                            <div class="Confermato" v-else>
                                <div class="h1-content">
                                    <h1>{{guest.NomeIntero}}</h1>
                                    <!-- Pulsante per Edit -->
                                    <button @click="EditRiepilogo(guest.Id)" class="edit-button">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </button>
                                </div>
                                <!-- Campo per diete/intolleranze -->
                                <p>Diete/Intolleranze: 
                                    <span class="underline">
                                        {{ formatOptions(guest.IdDiete, guest.Altro) }}
                                    </span>
                                </p>

                                <!-- Passaggio -->
                                <p v-if="guest.Passaggio">Passaggio: <span class="underline">{{ guest.Passaggio === true ? "Si" : "No" }}</span></p>
                            </div>
                        </div>
                    </div>



                    <div class="navigazioneForm">
                        <button class="primary-button" @click="AggiungiInvitato"><i class="fa-solid fa-plus"></i>Aggiungi nuovo partecipante</button>
                        <button class="primary-button">
                            <router-link :to="{ name: 'benvenuti-page' }" class="router-no-decoration">
                                Termina
                            </router-link>
                        </button>
                    </div>
                    <div class="text-center">
                        <h2>Per qualsiasi altra informazione o richiesta non farti problemi a scrivere a: </h2>
                        <h1 >Serena&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3473792082</h1>
                        <h1>Emmanuel&nbsp;&nbsp;&nbsp;&nbsp;3490773959</h1>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>