<template>
  
  <!-- 
  Loader Animato mole vesuvio
  <div class="loader-view2">
    <div class="loader2">
      <img src="../assets/img/LoaderSX.png" class="img-sx" alt="">
      <img src="../assets/img/LoaderDX.png" class="img-dx" alt="">
    </div>
  </div> 
  -->
  <div class="loader-view">
    <div class="loader">
        <div class="spinner"></div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: "LoaderAnimation",
  };
  </script>
  
  <style scoped>
  @use "colors" as *;
.loader-view {
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100vw;
    height: 100vh; 
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
}

.loader {
    position: relative;
    width: 150px; /* Dimensione aumentata */
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 80px; /* Dimensione aumentata */
    height: 80px;
    border: 4px solid #ccc; /* Aumentato spessore del bordo */
    border-top-color: #7f9c7e;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader-view2 {
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100vw;
  height: 100vh; 
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
  .loader2 {
    position: relative;
    width: 100px;
    height: 50px; /* Dimensione totale (rettangolo formato dai due quadrati) */
    align-items: center;
  }
  
  .img-sx {
    border-radius: 100%;
    z-index: 2;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    animation: rotateSX 1.5s infinite ease-in-out;
  }
  
  .img-dx {
    border-radius: 100%;
    left: 50px;
    z-index: 1;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    animation: rotateDX 1.5s infinite ease-in-out;
  }
  
  @keyframes rotateSX {
    0% {
      transform: translateX(0) scale(1); 
    }
    5% {
      transform: translateX(5px) scale(1.1); 
    }
    10% {
      transform: translateX(10px) scale(1.2); 
    }
    15% {
      transform: translateX(15px) scale(1.3); 
    }
    20% {
      transform: translateX(20px) scale(1.4); 
    }
    25% {
      transform: translateX(25px) scale(1.5);
      z-index: 1;
    }
    30% {
      transform: translateX(30px) scale(1.4); 
    }
    35% {
      transform: translateX(35px) scale(1.3); 
    }
    40% {
      transform: translateX(40px) scale(1.2); 
    }
    45% {
      transform: translateX(45px) scale(1.1); 
    }
    50% {
      transform: translateX(50px) scale(1); 
      z-index: 0;
    }
    55% {
      transform: translateX(45px) scale(0.9); 
    }
    60% {
      transform: translateX(40px) scale(0.8); 
    }
    65% {
      transform: translateX(35px) scale(0.7); 
    }
    70% {
      transform: translateX(30px) scale(0.6); 
    }
    75% {
      transform: translateX(25px) scale(0.5);
      z-index: 0;
    }
    80% {
      transform: translateX(20px) scale(0.6); 
    }
    85% {
      transform: translateX(15px) scale(0.7); 
    }
    90% {
      transform: translateX(10px) scale(0.8); 
    }
    95% {
      transform: translateX(5px) scale(0.9); 
    }
    100% {
      transform: translateX(0) scale(1);
      z-index: 1;
    }
  }

  @keyframes rotateDX {
    0% {
      transform: translateX(0) scale(1); 
    }
    5% {
      transform: translateX(-5px) scale(0.9); 
    }
    10% {
      transform: translateX(-10px) scale(0.8); 
    }
    15% {
      transform: translateX(-15px) scale(0.7); 
    }
    20% {
      transform: translateX(-20px) scale(0.6); 
    }
    25% {
      transform: translateX(-25px) scale(0.5); 
      z-index: 0;
    }
    30% {
      transform: translateX(-30px) scale(0.6); 
    }
    35% {
      transform: translateX(-35px) scale(0.7); 
    }
    40% {
      transform: translateX(-40px) scale(0.8); 
    }
    45% {
      transform: translateX(-45px) scale(0.9); 
    }
    50% {
      transform: translateX(-50px) scale(1);
      z-index: 1; 
    }
    55% {
      transform: translateX(-45px) scale(1.1); 
    }
    60% {
      transform: translateX(-40px) scale(1.2); 
    }
    65% {
      transform: translateX(-35px) scale(1.3); 
    }
    70% {
      transform: translateX(-30px) scale(1.4); 
    }
    75% {
      transform: translateX(-25px) scale(1.5); 
      z-index: 1;
    }
    80% {
      transform: translateX(-20px) scale(1.4); 
    }
    85% {
      transform: translateX(-15px) scale(1.3); 
    }
    90% {
      transform: translateX(-10px) scale(1.2); 
    }
    95% {
      transform: translateX(-5px) scale(1.1); 
    }
    100% {
      transform: translateX(0) scale(1); 
      z-index: 0;
    }
  }

</style>  