<template>
  <Doughnut :data="chartData" :options="options" />
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
//import axios from 'axios';
import config from '@/config';
ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "DonutChart",
  components: {
    Doughnut,
  },
  data() {
    return {
      apiBaseUrl: config.apiBaseUrl,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  props: ['chartData']
};
</script>
