<script>

export default {
  name: "AppFooter",
  data() {
    return {
    };
  }
};
</script>

<template>
  <footer class="text-center p-4 text-lg-start text-muted font-secondary">
    <div class="custom-footer">
      <div class="text-center text-lg-start text-muted font-secondary">
        <h1 class="text-center title-black">
          SERENA & EMMANUEL
        </h1>
        <div class="text-center pb-3">
          2 Maggio 2025 
        </div>
      </div>
    </div>
    <hr class="divider p-2">
    <div class="personal-footer">
      <div class="text-center text-lg-start text-muted font-secondary">
        <div class="text-center">
          Powered by Nicolò Fossati
          <button class=""  id="btnScrollTop">
            <i class="fa-solid fa-arrow-up"></i>
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>
 
<style scoped lang="scss">
@use "../styles/partials/colors" as *;
</style>
