<script>
</script>

<template>
    <div class="contattaci-container text-center">
        <h1>Contattaci, Ne saremo felici!</h1>
        <br>
        <div class="contatti">
            <h1>Serena Fossati&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3473792082</h1>
            <h1>Emmanuel Di Manso&nbsp;&nbsp;&nbsp;&nbsp;3490773959</h1>
        </div>
    </div>
</template>