import { createStore } from 'vuex';

export default new createStore({
    state: {
        token: localStorage.getItem('token') || '',
        isLoading: false
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        removeToken(state) {
            state.token = '';
            localStorage.removeItem('token');
        },
        setIsLoading(state, payload) {
            state.isLoading = payload;
        }
    },
    getters: {
        isAuthenticated: state => !!state.token,
        isLoading: state => state.isLoading
    }
});