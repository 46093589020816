<script>
export default {
  data() {
    return {
      copyMessage: "(clicca sull'iban per copiarlo negli appunti)" // Variabile per il messaggio di conferma
    };
  },
  methods: {
    copyIban() {
      const iban = "IT76N0623001127000036071914";
      
      // Creare un campo di input temporaneo per copiare il testo
      const tempInput = document.createElement("input");
      tempInput.value = iban;
      document.body.appendChild(tempInput);

      // Selezionare e copiare il testo
      tempInput.select();
      tempInput.setSelectionRange(0, 99999); // Per i dispositivi mobili

      try {
        document.execCommand("copy");
        this.copyMessage = "IBAN copiato negli appunti!";
        
        // Nascondere il messaggio dopo 3 secondi
        setTimeout(() => {
          this.copyMessage = "(clicca sull'iban per copiarlo negli appunti)";
        }, 3000);
      } catch (err) {
        console.error("Errore nella copia dell'IBAN: ", err);
      }

      // Rimuovere l'elemento temporaneo
      document.body.removeChild(tempInput);
    }
  }
}
</script>

<template>
    <div class="lista-nozze-container text-center">
      <h1>Lista Nozze</h1>
      <br>
      <p>Sappiamo che per tutti, chi più chi meno, è un lungo viaggio;</p>
      <br>
      <p>Le voci della nostra lista di nozze sono la presenza di ognuno di voi, che è per noi il dono più grande!</p>
      <br><br>
      <p>Ma se vorrete supportarci...</p>
      <br>
      <p>
        IBAN &nbsp;&nbsp; 
        <span ref="ibanText" @click="copyIban">IT76N0623001127000036071914</span>
        &nbsp; 
        <i class="fa-regular fa-copy" @click="copyIban" aria-hidden="true"></i>
      </p>
      <p v-if="copyMessage" class="copy-success-message">{{ copyMessage }}</p>
      <br>
      <p>Intestato a Emmanuel Di Manso e Serena Fossati</p>
    </div>
</template>  