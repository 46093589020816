<template>
    <div v-if="visible" class="error-alert">
      <p>{{ message }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      message: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        visible: true
      };
    },
    methods: {
      closeAlert() {
        this.visible = false;
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style>
  .error-alert {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
  }
  </style>
  