<template>
    <div class="accommodations-container">
      <h1 class="accommodations-title">Consigli per Pernottare</h1>
      <div class="accommodations-grid">
        <div v-for="accommodation in accommodations" :key="accommodation.name" class="accommodation-card">
          <a :href="accommodation.link" target="_blank" class="accommodation-link">
            <div class="card-content-accommodation">
              <h1 class="card-title-accommodation">{{ accommodation.name }}</h1>
              <p class="card-description-accommodation">{{ accommodation.description }}</p>
              <br>
              <p v-if="accommodation.distanza!=0" class="card-distanza-accomodation">Distanza dalla chiesa: {{ accommodation.distanza }} min (a piedi)</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        accommodations: [
          { name: 'Gaya Resort', link: 'https://www.allogi-campania.it/gaya-resort/', description: 'Alloggio con vista sul Duomo.', distanza:2 },
          { name: 'Le Stanze del Sogno', link: 'http://www.lestanzedelsogno.com/', description: 'Strutture multi camere adatto a gruppi nel centro storico di Benevento.', distanza:0 },
          { name: 'La Fara B&B', link: 'http://www.lafarabb.com/', description: 'B&B con 3 camere.', distanza:2 },
          { name: 'Guest House Orsini', link: 'https://guest-house-orsini.bellaitaliaholidays.top/', description: 'Hotel vicino al centro.', distanza:3 },
          { name: 'B&B 30 mt dal Duomo', link: 'https://www.allogi-campania.it/bb-30-mt-dal-duomo/', description: 'A pochi passi dal Duomo.', distanza:1 },
          { name: 'Al Duomo Benevento B&B', link: 'http://www.bbalduomobenevento.it/', description: 'B&B accogliente attaccato al Duomo.', distanza:3 },
          { name: 'Janara', link: 'https://www.janara.net/', description: 'Diversi appartamenti nel centro di Benevento.', distanza:0 },
          //{ name: 'Piazza Roma Rooms', link: 'http://www.piazzaromarooms.it/it/', description: 'Camere moderne con tutti i servizi.', distanza:10 },
          { name: 'Carpe Viam Guest House', link: 'https://www.carpeviamguesthouse.com/', description: 'B&B nel centro storico.', distanza:8 },
          { name: 'Booking - Benevento', link: 'https://www.booking.com/Share-BqYcW9U', description: 'Altre opzioni su Booking.com.', distanza:0 },
        ],
      };
    },
  };
</script>