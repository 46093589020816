<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
import LoaderAnimation from './components/Loader-Animation.vue';
import { mapState } from 'vuex';

export default {
  data() {
    return {
    };
  },
  components: {
    AppHeader,
    AppFooter,
    LoaderAnimation
  },
  computed: {
    ...mapState(['isLoading']),
  },
};
</script>

<template>
  <!-- Fontawesome -->
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
      integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw=="
      crossorigin="anonymous" referrerpolicy="no-referrer" />
  <!-- Google Fonts -->
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap"
    rel="stylesheet" />
  <link href="https://fonts.googleapis.com/css2?family=Italianno&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Nixie+One&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Indie+Flower&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap" rel="stylesheet">
  <AppHeader/>
  <div class="content">
      <router-view></router-view>
      <LoaderAnimation  v-if="isLoading"/>
    </div>
  <AppFooter/>
</template>

<style lang="scss">
@use "./styles/style.scss"; //Global SCSS
.content{
  padding-top: 95px; /* Stesso valore dell'altezza dell'header */
  background-color: #e9ecef; /* colore di sfondo per visibilità */ 
}
</style>
