<template>
    <div class="admindashboard-container">  
      <h1>Lista Invitati</h1>

      <div class="tabs">
        <h3
            class="tab" 
            :class="{ active: currentTab === 'invitati' }" 
            @click="setTab('invitati')">Invitati
        </h3>
            
        <h3
            class="tab" 
            :class="{ active: currentTab === 'confermati' }" 
            @click="setTab('confermati')">
            Presenti
        </h3>
        <h3 
            class="tab" 
            :class="{ active: currentTab === 'mancanti' }" 
            @click="setTab('mancanti')">
            Mancanti
        </h3>
        <h3 
            class="tab" 
            :class="{ active: currentTab === 'assenti' }" 
            @click="setTab('assenti')">
            Assenti
        </h3>
        <h3 
            class="tab" 
            :class="{ active: currentTab === 'whitelist' }" 
            @click="setTab('whitelist')">
            Whitelist
        </h3>
        <h3 
            class="tab" 
            :class="{ active: currentTab === 'stats' }" 
            @click="setTab('stats')">
            Statistiche
        </h3>
      <hr class="tab-divider" />
    </div>
    <hr />

    <div v-if=(!isStatsTab)>
      <div class="container-action-invitati">
      <div class="search-container">
        <input 
            type="text" 
            v-model="searchText" 
            @input="fetchGuests" 
            placeholder="Cerca" 
            class="search-input" 
        />
      </div>
      <div class="add-invitato">
        <button class="primary-button" @click="openAddInvitatoModal">Aggiungi invitato <i class="fa-solid fa-plus"></i></button>
      </div>
    </div>
    
    <table v-if="guests.length > 0">
      <thead>
          <tr>
            <th @click="sort(1)">Nome <span v-if="OrdinaPer === 1 && isDesc" class="arrow-up">&#9650;</span><span v-else-if="OrdinaPer === 1" class="arrow-down">&#9660;</span></th>
            <th @click="sort(2)">Presenza <span v-if="OrdinaPer === 2 && isDesc" class="arrow-up">&#9650;</span><span v-else-if="OrdinaPer === 2" class="arrow-down">&#9660;</span></th>
            <th>Dieta</th>
            <th @click="sort(3)">WhiteList <span v-if="OrdinaPer === 3 && isDesc" class="arrow-up">&#9650;</span><span v-else-if="OrdinaPer === 3" class="arrow-down">&#9660;</span></th>
            <th @click="sort(4)">Passaggio <span v-if="OrdinaPer === 4 && isDesc" class="arrow-up">&#9650;</span><span v-else-if="OrdinaPer === 4" class="arrow-down">&#9660;</span></th>
            <th></th>
          </tr>
      </thead>
      <div v-if="loading">Caricamento in corso...</div>
      <tbody>
          <tr v-for="guest in guests" :key="guest.id">
              <td>{{ guest.nomeIntero }}</td>
              <td>{{ guest.presenza ? 'Si':'No' }}</td>
              <td>
                <span v-if="guest.diete && guest.diete.length > 0" v-html="formatDieteString(guest.diete)"></span>

                <span v-else>-</span>
              </td>
              <td v-if="!this.isWhiteListTab">{{ guest.whiteList?'Si' : 'No' }}</td>
              <td v-else><input type="checkbox" v-model="guest.whiteList" @click="invertWhiteList(guest.id)"/></td>
              <td>{{ guest.passaggio ? 'Si':'No' }}</td>
              <td class="action-table">
                  <i class="fa-solid fa-pen-to-square" @click="openEditInvitatoModal(guest.id)"></i>
                  <i class="fa-regular fa-trash-can" @click="openDeleteInvitatoModal(guest.id)"></i>
              </td>
          </tr>
      </tbody>
    </table>


        <div class="pagination">
            <button @click="changePage(1)" :disabled="page === 1">Prima</button>
            <button @click="changePage(page - 1)" :disabled="page === 1">Precedente</button>

            <span>Pagina {{ page }} di {{ totPage }}</span>

            <button @click="changePage(page + 1)" :disabled="page >= totPage">Successivo</button>
            <button @click="changePage(totPage)" :disabled="page >= totPage">Ultima</button>
        </div>
    </div>
    <div v-else>
      <DonutChart :chartData="chartData"/>
    </div>

        <!-- Modale per aggiungere un invitato -->
        <div>
          <!-- Modale gestita direttamente con un div -->
          <div v-show="showAddModal" class="modale">
            <div class="modale-content">
              <h2>Nuovo Invitato</h2>
              <form @submit.prevent="addInvitato">
                <div>
                  <label for="nomeIntero">Nome Intero:</label>
                  <br>
                  <input type="text" v-model="newInvitato.NomeIntero" id="nomeIntero" required />
                </div>
                
                <div>
                  <label for="presenza">Presenza:</label>
                  <input type="checkbox" v-model="newInvitato.Presenza" id="presenza" />
                </div>
                
                <div class="diete-container">
                  <label>Seleziona Dieta:</label>
                  <div class="risposte-container-admin">
                    <div class="card-risposta-admin">
                      <div v-for="dieta in diete" :key="dieta.id">
                        <input type="checkbox" :id="dieta.dieta" :value="dieta.id" v-model="newInvitato.IdDiete">
                        <label :for="dieta.dieta">{{ dieta.dieta }}</label>
                      </div>
                    </div>
                  </div>
                  <textarea v-if="newInvitato.IdDiete.includes(5)" class="textarea-diete" v-model="newInvitato.Altro" placeholder="" maxlength="500"></textarea>
                </div>

                <div>
                  <label for="whiteList">White List</label>
                  <input type="checkbox" v-model="newInvitato.WhiteList" id="whiteList" />
                </div>

                <div v-if="newInvitato.WhiteList && newInvitato.WhiteList!=null">
                  <label for="passaggio">Passaggio</label>
                  <input type="checkbox" v-model="newInvitato.Passaggio" id="passaggio" />
                </div>
                
                <div class="modale-button-container">
                  <button type="submit" class="primary-button">Aggiungi</button>
                  <button type="button" class="primary-button" @click="showAddModal = false">Chiudi</button>
                </div>
              </form>
            </div>
          </div>
        </div>


        <!-- Modale per modificare un invitato -->
        <div>
          <!-- Modale gestita direttamente con un div -->
          <div v-show="showEditModal" class="modale">
            <div class="modale-content">
              <h2>Edit Invitato</h2>
              <form @submit.prevent="editInvitato">
                <div>
                  <label for="nomeIntero">Nome Intero:</label>
                  <br>
                  <input type="text" v-model="newInvitato.NomeIntero" id="nomeIntero" required />
                </div>
                
                <div>
                  <label for="presenza">Presenza:</label>
                  <input type="checkbox" v-model="newInvitato.Presenza" id="presenza" />
                </div>
                
                <div class="diete-container">
                  <label>Seleziona Dieta:</label>
                  <div class="risposte-container-admin">
                    <div class="card-risposta-admin">
                      <div v-for="dieta in diete" :key="dieta.id">
                        <input type="checkbox" :id="dieta.dieta" :value="dieta.id" v-model="newInvitato.IdDiete">
                        <label :for="dieta.dieta">{{ dieta.dieta }}</label>
                      </div>
                    </div>
                  </div>
                  <textarea v-if="newInvitato.IdDiete.includes(5)" class="textarea-diete" v-model="newInvitato.Altro" placeholder="" maxlength="500">asdca</textarea>
                </div>

                <div>
                  <label for="whiteList">White List</label>
                  <input type="checkbox" v-model="newInvitato.WhiteList" id="whiteList" />
                </div>

                <div v-if="newInvitato.WhiteList && newInvitato.WhiteList!=null">
                  <label for="passaggio">Passaggio</label>
                  <input type="checkbox" v-model="newInvitato.Passaggio" id="passaggio" />
                </div>
                
                <div class="modale-button-container">
                  <button type="submit" class="primary-button">Modifica</button>
                  <button type="button" class="primary-button" @click="showEditModal = false">Chiudi</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <!-- Modale per eliminare un invitato -->
        <div>
          <!-- Modale gestita direttamente con un div -->
          <div v-show="showDeleteModal" class="modale">
            <div class="modale-content">
              <h2>Elimina Invitato</h2>
              <span>Sei sicuro di voler eliminare questo invitato?</span>
              <div class="modale-button-container">
                <button type="submit" class="primary-button" @click="deleteInvitato" >Elimina</button>
                <button type="button" class="primary-button" @click="showDeleteModal = false">Cancella</button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </template>
  
  
<script>
  import DonutChart from '../../components/DonutChart.vue';
  import config from '@/config';
  import axios from 'axios';
  export default {
    data() {
        return {
            apiBaseUrl: config.apiBaseUrl,
            page: 1,
            record: 10,
            totPage: 1,
            guests: [],
            totRecord: 0,
            searchText: "",
            currentTab: 'invitati',
            showAddModal : false,
            showEditModal: false,
            showDeleteModal : false,
            OrdinaPer :0,
            IsDesc : false,
            newInvitato: {
              Id:null,
              NomeIntero: '',
              Presenza: null,
              IdDiete: [],
              Altro: null,
              Passaggio: null,
              WhiteList: null
            },
            loading: false,
            isWhiteListTab: false,
            isStatsTab : false,
            diete: [],
            chartData: {
              labels: [],
              datasets: [
                {
                  label: 'Numero invitati',
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                },
              ],
            },
        };
    },
  components: {
    DonutChart
  },
    methods: {
        logout() {
          localStorage.removeItem('token');
          this.$router.push('/login');
        },
        setTab(tab) {
          this.currentTab = tab;
          this.isWhiteListTab = (this.currentTab=="whitelist")? true :false;
          this.isStatsTab = (this.currentTab=="stats")? true :false;
          //this.currentTab=="whitelist"? this.isWhiteListTab=true :this.isWhiteListTab=false
          if(!this.isStatsTab){
            this.fetchGuests();
          } else {
            this.fetchChartData();
          }
        },
        openAddInvitatoModal() {
          this.newInvitato = { NomeIntero: '', Presenza: null, IdDiete: [],Altro:null, WhiteList:null, Passaggio: null }; // Resetta i campi
          this.showAddModal = true;
        },
        closeAddInvitatoModal() {
          this.showAddModal = false;
          this.newInvitato = { NomeIntero: '', Presenza: null, IdDiete: [],Altro:null, WhiteList:null, Passaggio: null }; // Resetta i campi
        },
        async addInvitato() {
          try {
            this.$store.commit('setIsLoading', true);
            if(this.newInvitato.WhiteList==null || this.newInvitato.WhiteList==false){
              this.newInvitato.Passaggio=null;
            }
            const response = await axios.post(`${this.apiBaseUrl}/api/AddInvitato`,this.newInvitato, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            });

            const result = response.data;
            if (result.success) {
              this.closeAddInvitatoModal(); 
              this.fetchGuests();
            } else {
            console.error("Errore nella risposta:", result.message);
            }
          } catch (error) {
              console.error("Errore durante l'aggiunta dell'invitato:", error);
          } finally{
            this.$store.commit('setIsLoading', false);
          }
        },
        openEditInvitatoModal(id) {
          this.getInvitatoById(id)
          this.showEditModal = true;
        },
        closeEditInvitatoModal() {
          this.showEditModal = false;
        },
        openDeleteInvitatoModal(id) {
          this.showDeleteModal = true;
          this.newInvitato.id = id;
        },
        async editInvitato() {
          try {
            this.$store.commit('setIsLoading', true);
            const response = await axios.put(`${this.apiBaseUrl}/api/EditInvitato`, this.newInvitato ,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
            });

            const result = response.data;
            if (result.success) {
              this.closeEditInvitatoModal();
              this.fetchGuests();
            } else {
              console.error("Errore nella risposta:", result.message);
            }
          } catch (error) {
            console.error("Errore durante la modifica dell'invitato:", error);
          } finally{
            this.$store.commit('setIsLoading', false);
          }
        },
        async getInvitatoById(id) {
          try {
            this.$store.commit('setIsLoading', true);
            const response = await axios.get(`${this.apiBaseUrl}/api/getInvitatoById?id=${id}`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            });

            const result = response.data;
            if (result.success) {
              this.newInvitato = {
                id: result.data.id,
                NomeIntero: result.data.nomeIntero || '', 
                Presenza: result.data.presenza,
                IdDiete: result.data.idDiete || '',
                Passaggio: result.data.passaggio,
                WhiteList: result.data.whiteList,
                Altro : result.data.altro || ''
              };
            } else {
              console.error("Errore nella risposta:", result.message);
            }
          } catch (error) {
            console.error("Errore durante la modifica dell'invitato:", error);
          } finally{
            this.$store.commit('setIsLoading', false);
          }
        },
        async getDiete() {
          try {
            this.$store.commit('setIsLoading', true);
            const response = await axios.get(`${this.apiBaseUrl}/api/GetDiete`);
            const result = response.data;
            if (result.success) {
              this.diete = result.data.result
            } else {
              console.error("Errore nella risposta:", result.message);
            }
          } catch (error) {
            console.error("Errore durante la modifica dell'invitato:", error);
          } finally{
            this.$store.commit('setIsLoading', false);
          }
        },
        
        async invertWhiteList(id) {
          try {
            this.$store.commit('setIsLoading', true);
            const response = await axios.put(`${this.apiBaseUrl}/api/InvertWhiteList?id=${id}`, {
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            });
            const result = response.data;
              if (result.success) {
                this.fetchGuests();
              } else {
                console.error("Errore nella risposta:", result.message);
              }
            } catch (error) {
            console.error("Errore durante l'eliminazione dell'invitato:", error);
          } finally{
            this.$store.commit('setIsLoading', false);
          }
        },
        async fetchGuests() {
            try {
            this.$store.commit('setIsLoading', true);
              this.loading = true;
              const payload = {
                page: this.page,
                record: this.record,
                testo: this.searchText || "",
                Filtro: this.currentTab,
                OrdinaPer: this.OrdinaPer,
                IsDesc: this.IsDesc
              }
              const response = await axios.post(`${this.apiBaseUrl}/api/SearchInvitati`,payload, {
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
              });
              const result = response.data;

              if (result.success) {
                  this.guests = result.data.invitati;                    
                  this.totPage = result.data.totPage;
                  this.totRecord = result.data.totRecord;
                  this.page = result.data.page;
              } else {
                  console.error("Errore nella risposta:", result.message);
              }
            } catch (error) {
              console.error("Errore durante il recupero degli invitati:", error);
            } finally {
              this.loading = false;
              this.$store.commit('setIsLoading', false);
            }
        },
        changePage(newPage) {
            if (newPage >= 1 && newPage <= this.totPage) {
                this.page = newPage;
                this.fetchGuests();
            }
        },
        sort(key) {
          // Se la chiave è già la stessa, inverti l'ordinamento
          if (this.OrdinaPer === key) {
              this.IsDesc = !this.IsDesc;
          } else {
              this.OrdinaPer = key;
              this.IsDesc = false; // Imposta l'ordinamento ascendente per una nuova chiave
          }

          // Invia una nuova richiesta al backend con i parametri aggiornati
          this.fetchGuests();
        },
        formatDieteString(diete) {
          return diete
            .map(d => d.id === 5 ? `<b class="altro-txt">${d.altro}</b>` : d.dieta)
            .join(', ');
        },async fetchChartData() {
          try {
            const response = await axios.get(`${this.apiBaseUrl}/api/GetStats`); // Sostituisci con l'URL del tuo backend
            const data = response.data; 
            if (data.success) {
              this.chartData.labels = data.data.map(item => item.label);
              this.chartData.datasets[0].data = data.data.map(item => item.data);
              this.chartData.datasets[0].backgroundColor = data.data.map(item => item.backgroundColor);
              console.log(this.chartData.label,"LABEL");
              console.log(this.chartData.datasets[0].data,"DATA");
              console.log(this.chartData.datasets[0].backgroundColor,"BACKGROUNDCOLOR");

              console.log(this.chartData,"Chart Donut AFTER");
              console.log('Fetched data:', data.data);
            }
          } catch (error) {
            console.error("Error fetching chart data:", error);
          }
        }
    },
    mounted() {
        this.fetchGuests();
        this.getDiete();
        console.log(this.diete);
        this.fetchChartData();
    }
  };
</script>
  